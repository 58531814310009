<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown">
    <v-card class="my-5 py-5"
      :class="$vuetify.breakpoint.smAndDown ? 'px-1' : 'px-5'"
    >
      <v-card-title class="mb-2 justify-center font-weight-bold">
        ご利用開始までの流れ
      </v-card-title>

      <v-card-text>
        <p v-html="introduction.content"></p>
      </v-card-text>

      <v-card-text
        :class="$vuetify.breakpoint.smAndDown ? 'px-5' : 'px-10'"
      >
        <v-timeline>
          <v-timeline-item
            v-for="step in steps"
            :key="step.id"
            :color="step.color"
            :icon="mdiIcon(step.id)"
          >
            <template v-slot:opposite>
              <span v-html="step.suplimental"></span>
            </template>

            <v-card
              dark
              :color="step.color"
            >
              <v-card-title class="text-h6">
                <v-icon class="mr-2">{{ mdiIcon(step.icon) }}</v-icon><span>{{ step.title }}</span>
              </v-card-title>
              <v-card-text class="pt-4 white text--secondary">
                <p v-html="step.description"></p>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>

      <v-row no-gutters
        justify="center"
        class="my-5"
      >
        <v-btn class="py-7 px-10 accent--text font-weight-bold elevation-1"
          small
          :to="{ name: 'signup' }"
        >
          お申し込みはこちら
        </v-btn>
      </v-row>

      <v-divider class="mt-10 mb-1"></v-divider>

      <v-row align="center">
        <v-col cols="12" class="pb-0">
          <v-card-title class="pb-0 justify-center">ご質問・お問い合わせ</v-card-title>
        </v-col>
        <v-col cols="12" sm="6" class="mt-n3 py-0 px-7">
          <p>
            システムの導入またはお申し込みに関してご質問がありましたら、<a href="/inquiry">お問い合わせページ</a>よりメールにてご連絡いただくか、
            右QRコードからサポートLINEを友だち追加していただきLINEにてお問い合わせください。
          </p>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-img
            src="../assets/support_line_qr.png"
            alt="サポートLINEアカウントQRコード"
          ></v-img>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import { API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError } from '@/module.js'

export default {
  components: {
  },

  props: {
  },

  data() {
    return {
      introduction: '',
      steps: [
        {
          id: 'numeric-1',
          icon: 'file-document-edit-outline',
          color: 'primary',
          itemName: 'submit_application',
          title: 'ご利用のお申し込み',
          description: '',
          suplimental: 'ステップ１<br />所要時間：およそ15分~30分',
        },
        {
          id: 'numeric-2',
          icon: 'email-open-multiple',
          color: 'primary',
          itemName: 'activate_account',
          title: '確認メールにてアカウントの有効化',
          description: '',
          suplimental: 'ステップ２<br />メール到着までのお時間：およそ1分~60分',
        },
        {
          id: 'numeric-3',
          icon: 'note-search-outline',
          color: 'accent',
          itemName: 'validate_application',
          title: '登録情報と営業届出確認書の確認',
          description: '',
          suplimental: 'ステップ３<br />所要時間：およそ1日（弊社作業）',
        },
        {
          id: 'numeric-4',
          icon: 'bank',
          color: 'primary',
          itemName: 'payment_info',
          title: '利用料金のお支払い',
          description: '',
          suplimental: 'ステップ４<br />期間：およそ5日営業日',
          },
        {
          id: 'numeric-5',
          icon: 'party-popper',
          color: 'accent',
          itemName: 'activate_all',
          title: 'ご契約プランの機能制限解除',
          description: '',
          suplimental: 'ステップ５<br />所要時間：およそ1日（弊社作業）',
        },
      ],
      publicApi: new ApiTool(API_ENDPOINT + '/'),
    };
  },

  computed: {
    mdiIcon() {
      return iconName => 'mdi-' + iconName
    },
    iToS() {
      return int => String(int)
    }
  },

  created() {
    this.getData()
  },

  methods: {
    getData() {
      this.setTemplateStrings()
    },

    setTemplateStrings() {
      this.publicApi.getReqSystemPublic('template-string/', {usedIn: 'application_procedure', itemName: ''}).then( results => {
        if (!results || !results.length) return

        this.introduction = results.find( template => template.item_name === 'introduction' )

        results.map( template => {
          const target = this.steps.find( step => step.itemName === template.item_name )
          if (target !== undefined) target.description = template.content
        })
      })
      .catch(error => CheckTokenError(error))
    },
  }
}
</script>

<style scoped>
</style>
